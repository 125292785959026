const stringHelpers = {

  toCamelCase(str, { upper } = { upper: false }) {
    const result = str.replace(/[-_\s](.)/g, (_, char) => char.toUpperCase())
    return upper ? result.charAt(0).toUpperCase() + result.slice(1) : result
  },

  /**
   * Check if a HTML string is empty or contains only white spaces
   * @param str
   * @returns {boolean}
   */
  isEmptyHtml(str) {
    return (str || "").replace(/<\/?[^>]+(>|$)/g, "").trim()  === ""
  },

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  },

  /**
   * Sanitize a string by removing invalid UTF-8 characters
   * @param {string} str - The string to sanitize
   * @returns {string} - The sanitized string
   */
  sanitizeString(str) {
    if (!str || typeof str !== "string") return str

    // Remove only invalid UTF-8 characters
    return [...str].filter(char => {
      const code = char.charCodeAt(0)
      // Keep all printable characters
      // Remove:
      // - Control characters (0-31)
      // - Replacement character (0xFFFD) which often appears with invalid encoding
      // - Incomplete UTF-16 surrogate pairs (0xD800-0xDFFF)
      // - Invalid BOM markers (0xFFFE, 0xFFFF)
      // - Zero-width spaces and other invisible formatting characters (0x200B-0x200F, 0x2028-0x202F)
      return !(code <= 31 ||
               code === 0xFFFD ||
               (code >= 0xD800 && code <= 0xDFFF) ||
               code === 0xFFFE ||
               code === 0xFFFF ||
               (code >= 0x200B && code <= 0x200F) || // Zero-width spaces, RTL/LTR marks
               (code >= 0x2028 && code <= 0x202F))   // Line/paragraph separators, narrow no-break spaces
    }).join("")
  },
}

export default stringHelpers
